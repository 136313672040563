

import { defineComponent } from 'vue';
import Navbar from '../components/Navbar.vue';
import NavbarMobile from '../components/NavbarMobile.vue';
import Hero from '../components/Hero.vue';
import ApeitaBtn from '../components/ApeitaBtn.vue';
import ScrollToTopButton from '../components/ScrollToTopButton.vue';
import SmallCard from '../components/SmallCard.vue';
import Footer from '../components/Footer.vue';

export default defineComponent({
    name: 'Contactos',
    data() {
        return {
          activatedNavbar: false,
            isAtTop: true,
            mobileMode: false,
            contactInfo: [
              {id: 0, link: "https://goo.gl/maps/urQSW3d6P5AZzo8S9", icon: "fa fa-map-marker", text: "R. de Diogo Cão 21, 7000-278 Évora"},
              {id: 1, link: "https://goo.gl/maps/N2QZJY9Zq3a2YK5y9", icon: "fa fa-music", text: "Ensaios todas as Terças e Quintas às 21:30 no Colégio Luis António Verney"},
              {id: 2, link: "mailto:seistetos@gmail.com", icon: "fa fa-envelope-o", text: "seistetos@gmail.com"}
            ]
        }
    },
    components: {
    Navbar,
    NavbarMobile,
    ApeitaBtn,
    ScrollToTopButton,
    Hero,
    SmallCard,
    Footer,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getImgURL(image: String) {
      return require('../assets/media/' + image);
    },
    scrollToElement(destination: string) {
      const element = document.querySelector(destination);

      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    },
    handleScroll () {
      window.pageYOffset >= 250 ? this.isAtTop = false : this.isAtTop = true;
    },
    handleResize () {
        this.mobileMode = window.innerWidth <= 1015;

        if(!this.mobileMode) {
            this.activatedNavbar = false;
        } 
    },
  },

});
