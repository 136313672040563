
import {defineComponent} from 'vue'
export default defineComponent({
    name: "SmallCard",
    props: {
        link: String,
        icon: String,
        text: String,
    }
});
